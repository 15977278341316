import { createSelector, createFeatureSelector } from '@ngrx/store';

import { notificationConfirmAdapter, NotificationConfirmState } from './notification-confirm.reducer';

// First, create a feature selector
export const selectNotificationConfirmState = createFeatureSelector<NotificationConfirmState>('notificationConfirm');

// Then, use this feature selector in your other selectors
export const selectAllNotificationConfirms = createSelector(
  selectNotificationConfirmState,
  notificationConfirmAdapter.getSelectors().selectAll
);

export const selectUnreadNotificationConfirms = createSelector(selectAllNotificationConfirms, notifications =>
  notifications.filter(notification => !notification.confirmed)
);
export const selectUnreadStickyNotificationConfirms = createSelector(selectAllNotificationConfirms, notifications =>
  notifications.filter(notification => !notification.confirmed && notification.sticky)
);

export const selectCreatedNotificationConfirms = createSelector(selectUnreadStickyNotificationConfirms, currentEntities => {
  const threshold = new Date(); // Define a threshold for what counts as 'new'
  threshold.setSeconds(threshold.getSeconds() - 5); // Example: new within the last 5 minutes
  return currentEntities.filter(entity => new Date(entity.created) > threshold);
});

export const selectActiveNotificationConfirm = createSelector(selectNotificationConfirmState, (state: NotificationConfirmState) =>
  state.selectedId ? state.entities[state.selectedId] : null
);

export const selectUnreadNotificationConfirmCount = createSelector(
  selectNotificationConfirmState,
  (state: NotificationConfirmState) => state.count
);
