import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { User as DelonUser, Layout, SettingsService } from '@delon/theme';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { BehaviorSubject } from 'rxjs';
import { MosaicAppSettings } from 'src/app/core/app.state';
import { PlatformRole } from 'src/app/graphql/data-graphql';
import { UserAvatarComponent } from 'src/app/shared/components/user/user-avatar/user-avatar.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

import { ClearStorageActionComponent } from './clear-storage-action.component';
import { FullScreenActionComponent } from './fullscreen-action.component';
import { HotkeysActionComponent } from './hotkeys-action.component';

@Component({
  selector: 'header-user',
  template: `
    @if (authService.currentUser) {
      <div class="alain-default__nav-item d-flex align-items-center" nz-dropdown nzPlacement="bottomRight" [nzDropdownMenu]="userMenu">
        <app-user-avatar
          [userName]="
            authService.currentUser.first_name || authService.currentUser.last_name
              ? (authService.currentUser.first_name ?? '') + ' ' + (authService.currentUser.last_name ?? '')
              : authService.currentUser.email
          "
          [userMail]="authService.currentUser.email"
          [showWithHeaderColor]="true" />
        <nz-dropdown-menu #userMenu="nzDropdownMenu">
          <div nz-menu class="width-sl">
            <div class="avatar-padding">
              <app-user-avatar
                [userName]="
                  authService.currentUser.first_name || authService.currentUser.last_name
                    ? (authService.currentUser.first_name ?? '') + ' ' + (authService.currentUser.last_name ?? '')
                    : authService.currentUser.email
                "
                [userMail]="authService.currentUser.email"
                [showNameAndMail]="true" />
            </div>
            <li nz-menu-divider></li>
            <div nz-menu-item (click)="openAccountSettings()">
              <i nz-icon nzType="setting" class="mr-sm"></i>
              {{ 'BASIC.USER.editProfile' | translate }}
            </div>
            <div nz-menu-item (click)="openPasswordSettings()">
              <i nz-icon nzType="lock" class="mr-sm"></i>
              {{ 'BASIC.USER.changePassword' | translate }}
            </div>
            <li nz-menu-divider></li>
            <div nz-menu-item>
              <hotkeys-action />
            </div>
            <div nz-menu-item>
              <fullscreen-action />
            </div>
            <div nz-menu-item>
              <clear-storage-action />
            </div>
            <li nz-menu-divider></li>
            <div nz-menu-item (click)="$event.stopImmediatePropagation()">
              <nz-radio-group [ngModel]="translate.currentLang" (ngModelChange)="changeLanguage($event)" (click)="$event.stopPropagation()">
                @for (lang of availableLangues$ | async; track lang) {
                  <label nz-radio-button [nzValue]="lang">
                    {{ 'LANGUAGE.' + lang | translate }}
                  </label>
                }
              </nz-radio-group>
              <!-- <nz-select  (click)="$event.stopPropagation()">
              <nz-option *ngFor="let lang of translate.getLangs()" [nzValue]="lang" [nzLabel]="lang">{{ lang }}</nz-option>
            </nz-select> -->
            </div>
            <li nz-menu-divider></li>
            <div nz-menu-item (click)="authService.logout()">
              <i nz-icon nzType="logout" class="mr-sm"></i>
              {{ 'BASIC.USER.logout' | translate }}
            </div>
          </div>
        </nz-dropdown-menu>
      </div>
    }
  `,
  styles: ['.avatar-padding {padding: 5px 12px;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NzDropDownModule,
    UserAvatarComponent,
    NzMenuModule,
    NzButtonModule,
    NzIconModule,
    FullScreenActionComponent,
    ClearStorageActionComponent,
    NzRadioModule,
    FormsModule,
    TranslateModule,
    HotkeysActionComponent,
    AsyncPipe
  ]
})
export class HeaderUserComponent {
  get user(): DelonUser {
    return this.settings.user;
  }

  selectedLang: string;
  availableLangues$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private settings: SettingsService<Layout, DelonUser, MosaicAppSettings>,
    private router: Router,
    public authService: AuthService,
    private usersService: UserService,
    public translate: TranslateService
  ) {
    let langs = translate.getLangs();
    let filteredLangs = langs.filter(l => authService.acl.can([PlatformRole.Developer]) || l != 'none');

    this.availableLangues$.next(filteredLangs);
    this.selectedLang = this.translate.defaultLang;
  }

  logout(): void {
    this.authService.logout();
  }

  openAccountSettings() {
    this.usersService.currentUser().subscribe(user => {
      this.usersService.openEditModal(user as any);
    });
  }

  openPasswordSettings() {
    this.usersService.currentUser().subscribe(user => {
      this.usersService.openPasswordModal(user as any);
    });
  }

  changeLanguage($event: string) {
    this.translate.use($event);
    localStorage.setItem('selectedLanguage', $event);
  }
}
