import { importProvidersFrom, inject } from '@angular/core';
import { Routes } from '@angular/router';
import { authSimpleCanActivate, authSimpleCanActivateChild } from '@delon/auth';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxEchartsModule } from 'ngx-echarts';

import { DocumentClassBasicSettingsComponent } from './admin/document-class-fs/document-class-details/document-class-basic-settings/document-class-basic-settings.component';
import { DocumentClassExportSettingsComponent } from './admin/document-class-fs/document-class-details/document-class-export-settings/document-class-export-settings.component';
import { DocumentClassPredictorSettingsComponent } from './admin/document-class-fs/document-class-details/document-class-predictor-settings/document-class-predictor-settings.component';
import { ExporterEditComponent } from './admin/exporters/exporter-edit/exporter-edit.component';
import { NotificationEditComponent } from './admin/notifications/notification-edit/notification-edit.component';
import { PredictorEditComponent } from './admin/predictors/predictor-edit/predictor-edit.component';
import { PredictionService } from './documents/document/classification/extraction/prediction.service';
import { ExceptionComponent } from './exception/exception.component';
import { ExceptionTriggerComponent } from './exception/trigger.component';
import { LayoutBasicComponent } from '../layout/basic/basic.component';
import { FORMLY_PROVIDERS } from '../layout/formly';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
import { MaintenanceComponent } from '../shared/components/maintenance/maintenance.component';

export function canDeactivateWithLocalChanges<C>(hasLocalChanges: (component: C) => boolean) {
  return (c: C) =>
    new Promise(resolve =>
      hasLocalChanges(c)
        ? inject(NzModalService).confirm({
            nzTitle: inject(TranslateService).instant('COMMON.CONFIRM.canDeactivateLocalChanges'),
            nzOnOk: () => resolve(true),
            nzOnCancel: () => resolve(false),
            nzCancelText: inject(TranslateService).instant('COMMON.ACTIONS.cancel')
          })
        : resolve(true)
    );
}

export const appRoutes: Routes = [
  {
    path: '',
    component: LayoutBasicComponent,
    canActivate: [authSimpleCanActivate],
    canActivateChild: [authSimpleCanActivateChild],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        providers: [
          importProvidersFrom(
            NgxEchartsModule.forRoot({
              echarts: () => import('echarts')
            })
          )
        ],
        children: [{ path: '', loadComponent: () => import('./dashboard/dashboard.component').then(m => m.DashboardComponent) }],
        data: { reuse: false }
      },
      {
        path: 'documents',
        providers: [PredictionService],
        children: [
          {
            path: '',
            loadComponent: () => import('./documents/documents.component').then(m => m.DocumentsComponent),
            data: { reuse: true }
          },
          {
            path: 'document-center',
            loadComponent: () => import('./documents/document-center/document-center.component').then(m => m.DocumentCenterComponent),
            data: { reuse: false }
          },
          {
            path: 'inbox',
            loadComponent: () => import('./documents/inbox-view/inbox-view.component').then(m => m.InboxViewComponent),
            data: { reuse: false }
          },
          {
            path: 'inbox/:category',
            loadComponent: () => import('./documents/inbox-view/inbox-view.component').then(m => m.InboxViewComponent),
            data: { reuse: false }
          },
          {
            path: ':documentId',
            loadComponent: () =>
              import('./documents/document-edit-page/document-edit-page.component').then(m => m.DocumentEditPageComponent),
            data: { reuse: false }
          }
        ]
      },
      {
        path: 'admin',
        children: [
          { path: 'users', loadComponent: () => import('./admin/users/users.component').then(m => m.UsersComponent) },
          {
            path: 'organizations',
            children: [
              {
                path: '',
                loadComponent: () => import('./admin/organizations/organizations.component').then(m => m.OrganizationsComponent)
              },
              {
                path: 'edit', // for creating new org
                loadComponent: () =>
                  import('./admin/organizations/organization-edit-page/organization-edit-page.component').then(
                    m => m.OrganizationEditPageComponent
                  )
              },
              {
                path: 'edit/:organizationId',
                loadComponent: () =>
                  import('./admin/organizations/organization-edit-page/organization-edit-page.component').then(
                    m => m.OrganizationEditPageComponent
                  )
              }
            ]
          },
          {
            path: 'predictors',
            loadComponent: () => import('./admin/predictors/predictors.component').then(m => m.PredictorsComponent),
            children: [
              {
                path: ':predictorId',
                loadComponent: () =>
                  import('./admin/predictors/predictor-edit/predictor-edit.component').then(m => m.PredictorEditComponent),
                canDeactivate: [canDeactivateWithLocalChanges<PredictorEditComponent>(c => c.hasLocalChanges())]
              }
            ]
          },
          {
            path: 'notifications',
            loadComponent: () => import('./admin/notifications/notifications.component').then(m => m.NotificationsComponent),
            children: [
              {
                path: ':notificationId',
                loadComponent: () =>
                  import('./admin/notifications/notification-edit/notification-edit.component').then(m => m.NotificationEditComponent),
                canDeactivate: [canDeactivateWithLocalChanges<NotificationEditComponent>(c => c.hasLocalChanges())]
              }
            ]
          },
          {
            path: 'exporters',
            // Todo: providers: [PredictorsService],
            loadComponent: () => import('./admin/exporters/exporters.component').then(m => m.ExportersComponent),
            children: [
              {
                path: ':exporterId',
                loadComponent: () => import('./admin/exporters/exporter-edit/exporter-edit.component').then(m => m.ExporterEditComponent),
                canDeactivate: [canDeactivateWithLocalChanges<ExporterEditComponent>(c => c.hasLocalChanges())]
              }
            ]
          },
          {
            path: 'workflow',
            loadComponent: () => import('./admin/workflow/workflow-overview.component').then(m => m.WorkflowOverviewComponent),
            children: [
              {
                path: 'vs-tree',
                loadComponent: () => import('./admin/workflow/workflow-tree/workflow-tree.component').then(m => m.WorkflowTreeComponent)
              },
              {
                path: 'billing',
                loadComponent: () =>
                  import('./admin/workflow/workflow-billing/workflow-billing.component').then(m => m.WorkflowBillingComponent)
              }
            ]
          },
          {
            path: 'document-class-fs',
            // Todo: providers: [DocumentClassFsService],
            children: [
              { path: '', redirectTo: 'null', pathMatch: 'prefix', data: { reuse: false } },
              {
                path: ':rootId',
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import('./admin/document-class-fs/document-class-fs.component').then(m => m.DocumentClassFsComponent),
                    children: [
                      {
                        path: 'preview/:selectedId',
                        providers: [FORMLY_PROVIDERS],
                        loadComponent: () =>
                          import('./admin/document-class-fs/document-class-details/document-class-details.component').then(
                            m => m.DocumentClassDetailsComponent
                          ),
                        children: [
                          { path: '', redirectTo: 'basic', pathMatch: 'prefix' },
                          {
                            path: 'basic',
                            loadComponent: () =>
                              import(
                                './admin/document-class-fs/document-class-details/document-class-basic-settings/document-class-basic-settings.component'
                              ).then(m => m.DocumentClassBasicSettingsComponent),
                            canDeactivate: [canDeactivateWithLocalChanges<DocumentClassBasicSettingsComponent>(c => c.hasLocalChanges())]
                          },
                          {
                            path: 'predictor',
                            loadComponent: () =>
                              import(
                                './admin/document-class-fs/document-class-details/document-class-predictor-settings/document-class-predictor-settings.component'
                              ).then(m => m.DocumentClassPredictorSettingsComponent),
                            canDeactivate: [
                              canDeactivateWithLocalChanges<DocumentClassPredictorSettingsComponent>(c => c.form.dirty && c.form.touched)
                            ]
                          },
                          {
                            path: 'export',
                            loadComponent: () =>
                              import(
                                './admin/document-class-fs/document-class-details/document-class-export-settings/document-class-export-settings.component'
                              ).then(m => m.DocumentClassExportSettingsComponent),
                            canDeactivate: [
                              canDeactivateWithLocalChanges<DocumentClassExportSettingsComponent>(c => c.form.dirty && c.form.touched)
                            ]
                          },
                          {
                            path: 'raw',
                            loadComponent: () =>
                              import(
                                './admin/document-class-fs/document-class-details/document-class-raw-settings/document-class-raw-settings.component'
                              ).then(m => m.DocumentClassRawSettingsComponent)
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'exception',
        children: [
          { path: '403', component: ExceptionComponent, data: { type: 403 } },
          { path: '404', component: ExceptionComponent, data: { type: 404 } },
          { path: '500', component: ExceptionComponent, data: { type: 500 } },
          { path: 'trigger', component: ExceptionTriggerComponent }
        ]
      },
      {
        path: 'external',
        children: [{ path: ':label', loadComponent: () => import('./external/external.component').then(m => m.ExternalComponent) }]
      }
    ]
  },
  // passport
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      {
        path: 'login',
        loadComponent: () => import('./passport/login/login.component').then(m => m.UserLoginComponent),
        data: { title: 'Login', titleI18n: 'pro-login' }
      }
    ]
  },
  // Maintenance
  {
    path: 'maintenance',
    children: [
      {
        path: '',
        component: MaintenanceComponent
      }
    ]
  },
  { path: '**', redirectTo: 'exception/404' }
];
